@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/gridle";@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/colors";
.drawer {
	height: 100vh;
	width: 100vw;
	overflow: scroll;
	display: flex;
	flex-direction: column;
	background: white;

	&__overlay {
		&::before {
			content: "";
			background: rgba(0, 0, 0, 0.65);
			position: fixed;
			top: 0;
			bottom: 0;
			right: 0;
			left: 0;
			z-index: 9;
			transition: background-color 1s ease;
		}
	}
}
