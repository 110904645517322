@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/gridle";@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/colors";
$border-radius-big: 10px !default;

%active-state {
	color: white;
	background: var(--color-active, $active-color);
	position: relative;
	padding: 0;

	svg {
		width: 16px;
		height: 16px;
	}
}

%select-range-support {
	content: "";
	position: absolute;
	top: 0;
	background: $grey-light;
	width: 50%;
	height: 100%;
	z-index: -1;
}

.date-picker-calendar-tile__tile {
	&.react-calendar__tile {
		&.react-calendar__tile--hasActive {
			@extend %active-state;

			&:not(.react-calendar__tile--rangeEnd) {
				border-bottom-left-radius: $border-radius-big;
				border-top-left-radius: $border-radius-big;
			}
		}

		&--range {
			background: var(--color-gray-300, $grey-light);

			&.react-calendar__tile--rangeStart {
				@extend %active-state;
				overflow: visible !important;
				border-bottom-left-radius: $border-radius-big;
				border-top-left-radius: $border-radius-big;

				&:before {
					@extend %select-range-support;
					right: 0;
				}
			}

			&.react-calendar__tile--rangeEnd {
				@extend %active-state;
				overflow: visible !important;
				border-bottom-right-radius: $border-radius-big;
				border-top-right-radius: $border-radius-big;

				&:before {
					@extend %select-range-support;
					left: 0;
				}
			}
		}
	}
}
